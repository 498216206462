
import { defineComponent } from "vue";
import store from "@/store/index";
import { WorkspaceMeta } from "@/store/workspace/types";
import AuthGuard from "./AuthGuard.vue";

export default defineComponent({
  name: "Foyer",
  components: {
    AuthGuard,
  },
  data() {
    return {
      workspaces: [] as Array<WorkspaceMeta>,
    };
  },
  methods: {
    handleValid: async function () {
      store.getters["user/getWorkspaces"].forEach(async (workspace: string) => {
        this.workspaces.push(
          await store.getters["user/getWorkspaceMeta"](workspace)
        );
      });
    },
    logout: function () {
      this.$gapi.logout();
      store.dispatch("user/unload");
    },
  },
});
